<template>
  <v-app>
    <v-system-bar dark app height="30" color="accent" v-if="! $vuetify.breakpoint.xs && ! $vuetify.breakpoint.sm">    
      <v-row class="pa-0 ma-0">
        <v-col cols="1" lg="1" class="pa-0 ma-0 hidden-md-and-down">
        </v-col>
        <v-col class="pa-0 ma-0 font-weight-bold" cols="12" lg="10">
          <v-icon>mdi-email</v-icon>
          <span class="pr-5"><a href="mailto:info@powermanagementit.com" style="color: inherit">info@powermanagementit.com</a></span>  
          <v-icon>mdi-phone</v-icon>
          <span class="pr-5"><a href="tel:02871140206" style="color: inherit">028 7114 0206</a></span> 
        </v-col>
        <v-col cols="1" lg="1" class="pa-0 ma-0 hidden-md-and-down">
        </v-col>
      </v-row>
    </v-system-bar>
    <v-app-bar app elevate-on-scroll>
      <v-row>
        <v-col cols="6" md="2" >
          <v-app-bar-nav-icon 
            v-if="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm"
            @click.stop="sidebarMenu = !sidebarMenu"
          ></v-app-bar-nav-icon>
        </v-col>
        <v-col cols="8" v-if="! $vuetify.breakpoint.xs && ! $vuetify.breakpoint.sm">
          <v-toolbar-items style="height: 100%" class="justify-space-around">
            <v-btn 
              v-for="(item, i) in menuItems" :key="i"
              :to="item.linkTo"
              plain
              color="primary"
            >{{ item.text }}</v-btn>
          </v-toolbar-items>
        </v-col>
        <v-col cols="6" md="2">
          <v-toolbar-title>
            <v-img 
              class="ml-auto"
              alt="Power Management IT Logo" 
              src="@/assets/logo.svg" 
              max-width="160px"
              :contain="true"
            ></v-img>
          </v-toolbar-title>
        </v-col>
      </v-row>
    </v-app-bar>
    <v-navigation-drawer
      v-if="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm"
      v-model="sidebarMenu"
      temporary
      color="primary"
      dark
      app
    >
      <v-list dense>
        <v-list-item 
          v-for="(item, i) in menuItems" :key="i"
          @click="sidebarMenu = ! sidebarMenu; $router.push(item.linkTo)"
          link 
          color="secondary"
        >{{ item.text }}</v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-main>     
      <router-view></router-view>
    </v-main>
    <v-footer color="accent" dark>   
      <v-row class="pa-0 ma-0" style="opacity: 0.8">
        <v-col class="font-weight-bold text-center" cols="12">
          <v-row>
            <v-col cols="2" class="hidden-sm-and-down">
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <div class="pb-2 text-h5">Our Services</div>
              <a href="/services/energy-trading" style="color: inherit">Energy Trading</a><br/>
              <a href="/services/energy-price-alerts" style="color: inherit">Energy Price Alerts</a><br/>
              <a href="/services/nie-connection-configuration" style="color: inherit">NIE Connection Configuration</a><br/>
              <a href="/services/nie-applications" style="color: inherit">NIE Applications</a><br/>
              <a href="/services/mic-brokerage" style="color: inherit">MIC Brokerage</a><br/>
              <a href="/services/ev-charging" style="color: inherit">EV Charging</a><br/>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <div class="pb-2 text-h5">Other Links</div>
              <a href="/" style="color: inherit">About Us</a><br/>
              <a href="/contact-us" style="color: inherit">Contact Us</a><br/>
              <a href="/careers" style="color: inherit">Careers</a><br/>
            </v-col>
          </v-row>
        </v-col>
        <v-col class="font-weight-500 text-center pb-0" style="font-size: 0.9em" cols="12">
          <v-icon small class="pt-0 mt-n1">mdi-copyright</v-icon>
          All rights reserved, Power Management IT Ltd, Registration No. NI667207
        </v-col>
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      sidebarMenu: false,
      menuItems: [
        { text: 'About Us', linkTo: '/'},
        { text: 'Services', linkTo: '/services'},
        { text: 'Careers', linkTo: '/careers'},
        { text: 'Contact Us', linkTo: '/contact-us'}
      ]
    }
  },
  created() {
    this.onScreenSizeChange()
    window.addEventListener("resize", this.onScreenSizeChange);
  },
  destroyed() {
    window.removeEventListener("resize", this.onScreenSizeChange);
  },
  methods: {
    onScreenSizeChange() {
      var root = document.querySelector(":root");
      if (this.$vuetify.breakpoint.xs) {
        root.style.fontSize = "12px";
      } else if (this.$vuetify.breakpoint.sm) {
        root.style.fontSize = "15px";
      } else {
        root.style.fontSize = "16px";
      }
    }
  }
}
</script>

<style lang="scss">
  h1, h2, h3 {
    font-weight: normal;
    color: var(--v-primary-base);
  }
</style>