export const routes = [
  { 
    path: '*', 
    component: () => import(/* webpackChunkName: "NotFoundPage" */ './views/NotFoundPage.vue'),
    meta: {
      sitemap: {
          priority:    0.0
      }
    }
  },
  { 
    path: '/', 
    component: () => import(/* webpackChunkName: "AboutUsPage" */ './views/AboutUsPage.vue'),
    meta: {
      title: 'About Us - Power Management IT',
      metaTags: [
        {
          name: 'description',
          content: 'Using technology to save Northern Ireland businesses money on their electric supply, through data analytics and a vast knowledge of the energy industry.'
        },
        {
          property: 'og:description',
          content: 'Using technology to save Northern Ireland businesses money on their electric supply, through data analytics and a vast knowledge of the energy industry.'
        }
      ],
      sitemap: {
          priority:    1.0
      }
    }
  },
  { 
    path: '/services', 
    component: () => import(/* webpackChunkName: "ServicesPage" */ './views/ServicesPage.vue'),
    meta: {
      title: 'Services - Power Management IT',
      metaTags: [
        {
          name: 'description',
          content: 'Services include Energy Brokerage, Maximum Import Capacity (MIC) Brokerage, Price Alerts, Energy Trading, NIE Networks Applications and EV Charging Consultancy.'
        },
        {
          property: 'og:description',
          content: 'Services include Energy Brokerage, Maximum Import Capacity (MIC) Brokerage, Price Alerts, Energy Trading, NIE Networks Applications and EV Charging Consultancy.'
        }
      ],
      sitemap: {
          priority:    0.9
      }
    }
  },
  { 
    path: '/services/energy-trading', 
    component: () => import(/* webpackChunkName: "EnergyTradingPage" */ './views/EnergyTradingPage.vue'),
    meta: {
      title: 'Energy Trading - Power Management IT',
      metaTags: [
        {
          name: 'description',
          content: 'Whether it’s selling your Generation or purchasing Consumption, we use machine learning and data analytics to ensure we get the best value in the I-SEM.'
        },
        {
          property: 'og:description',
          content: 'Whether it’s selling your Generation or purchasing Consumption, we use machine learning and data analytics to ensure we get the best value in the I-SEM.'
        }
      ],
      sitemap: {
          priority:    0.8
      }
    }
  },
  { 
    path: '/services/nie-applications', 
    component: () => import(/* webpackChunkName: "NIEApplicationsPage" */ './views/NIEApplicationsPage.vue'),
    meta: {
      title: 'NIE Networks Applications - Power Management IT',
      metaTags: [
        {
          name: 'description',
          content: 'We take the hassle out of NIE Applications ensuring a satisfactory solution for the least possible cost. MIC Load increase applications are our specialty.'
        },
        {
          property: 'og:description',
          content: 'We take the hassle out of NIE Applications ensuring a satisfactory solution for the least possible cost. MIC Load increase applications are our specialty.'
        }
      ],
      sitemap: {
          priority:    0.8
      }
    }
  },
  { 
    path: '/services/nie-connection-configuration', 
    component: () => import(/* webpackChunkName: "NIEConnectionConfigurationPage" */ './views/NIEConnectionConfigurationPage.vue'),
    meta: {
      title: 'NIE Networks Connection Configuration - Power Management IT',
      metaTags: [
        {
          name: 'description',
          content: 'We can analyse your NIE Grid Connection to ensure you have the correct configuration for your energy needs. Potentially saving thousands on your monthly bills.'
        },
        {
          property: 'og:description',
          content: 'We can analyse your NIE Grid Connection to ensure you have the correct configuration for your energy needs. Potentially saving thousands on your monthly bills.'
        }
      ],
      sitemap: {
          priority:    0.8
      }
    }
  },
  { 
    path: '/services/nie-connection-configuration/pass-through-charges', 
    component: () => import(/* webpackChunkName: "PassThroughChargesPage" */ './views/PassThroughChargesPage.vue'),
    meta: {
      title: 'Energy Pass Through Charges Explained - Power Management IT',
      metaTags: [
        {
          name: 'description',
          content: 'A detailed explanation of the 15 different pass through charges that are included in Northern Ireland electricity bills, yet few understand or know about them.'
        },
        {
          property: 'og:description',
          content: 'A detailed explanation of the 15 different pass through charges that are included in Northern Ireland electricity bills, yet few understand or know about them.'
        }
      ],
      sitemap: {
          priority:    0.5
      }
    }
  },
  { 
    path: '/services/energy-price-alerts', 
    component: () => import(/* webpackChunkName: "EnergyPriceAlertsPage" */ './views/EnergyPriceAlertsPage.vue'),
    meta: {
      title: 'Energy Pass Through Charges Explained - Power Management IT',
      metaTags: [
        {
          name: 'description',
          content: 'We can provide you with SMS alerts when energy prices go higher or lower than pre-defined prices. This information could be used to switch off or on appliances.'
        },
        {
          property: 'og:description',
          content: 'We can provide you with SMS alerts when energy prices go higher or lower than pre-defined prices. This information could be used to switch off or on appliances.'
        }
      ],
      sitemap: {
          priority:    0.8
      }
    }
  },
  { 
    path: '/services/mic-brokerage', 
    component: () => import(/* webpackChunkName: "MICBrokeragePage" */ './views/MICBrokeragePage.vue'),
    meta: {
      title: 'Maximum Import Capacity (MIC) Brokerage - Power Management IT',
      metaTags: [
        {
          name: 'description',
          content: 'As an MIC Broker we bring businesses with spare Capacity together with businesses who need to increase theirs. Saving £££’s on both their monthly bills.'
        },
        {
          property: 'og:description',
          content: 'As an MIC Broker we bring businesses with spare Capacity together with businesses who need to increase theirs. Saving £££’s on both their monthly bills.'
        }
      ],
      sitemap: {
          priority:    0.9
      }
    }
  },
  { 
    path: '/services/ev-charging', 
    component: () => import(/* webpackChunkName: "EVChargingPage" */ './views/EVChargingPage.vue'),
    meta: {
      title: 'EV Charging - Power Management IT',
      metaTags: [
        {
          name: 'description',
          content: 'We can advise on the best location for your EV Chargers, the size & quantity of charges supportable on your current connection & take care of any NIE applications required to upgrade your connection.'
        },
        {
          property: 'og:description',
          content: 'We can advise on the best location for your EV Chargers, the size & quantity of charges supportable on your current connection & take care of any NIE applications required to upgrade your connection.'
        }
      ],
      sitemap: {
          priority:    0.5
      }
    }
  },
  { 
    path: '/contact-us', 
    component: () => import(/* webpackChunkName: "ContactUsPage" */ './views/ContactUsPage.vue'),
    meta: {
      title: 'Contact Us - Power Management IT',
      metaTags: [
        {
          name: 'description',
          content: 'Contact us today on 028 7114 0206 or email us at info@powermanagementit.com to find out how we can help your business.'
        },
        {
          property: 'og:description',
          content: 'Contact us today on 028 7114 0206 or email us at info@powermanagementit.com to find out how we can help your business.'
        }
      ],
      sitemap: {
          priority:    0.8
      }
    }
  },
  { 
    path: '/careers', 
    component: () => import(/* webpackChunkName: "CareersPage" */ './views/CareersPage.vue'),
    meta: {
      title: 'Careers - Power Management IT',
      metaTags: [
        {
          name: 'description',
          content: 'Join our growing team of energy consultants and technology experts as we continue on our journey to make a smarter, greener energy market in Northern Ireland.'
        },
        {
          property: 'og:description',
          content: 'Join our growing team of energy consultants and technology experts as we continue on our journey to make a smarter, greener energy market in Northern Ireland.'
        }
      ],
      sitemap: {
          priority:    0.6
      }
    }
  }
]